<backbone>
  <section header-page="topbar" class="d-flex h-100 position-relative">
    <div class="w-50 d-flex justify-content-start align-items-center">
      <p class="m-0 fw-medium">Nuevo lead</p>
    </div>

    <div class="w-50 d-flex justify-content-end align-items-center gap-3">
      <breadcrumb
        [links]="[{title: 'Dashboard'}, {title: 'Leads Solviiforma', link: '/leads/solviiforma'}, {title: 'Detalles'}]" />

      <p-button size="small" icon="pi pi-save" label="Crear" [loading]="isSaving" (onClick)="save()"
        [disabled]="!form.valid"></p-button>
    </div>
  </section>

  <section wrapper>
    <form [formGroup]="form" [ngClass]="{'opacity-50 pointer-events-none user-select-none': this.isSaving === true}">
      <div class="card page-section rounded-2 shadow-sm">
        <div class="card-header align-items-center d-flex rounded-top-2">
          <h6 class="card-title flex-grow-1 m-0">Información de contacto.</h6>
        </div>
        <div class="card-body">
          <div class="mb-3 d-flex gap-2">
            <div class="d-flex flex-column gap-2 w-50">
              <input formControlName="name" type="text" pInputText class="w-100 h-50px" />
              <small class="ps-2">Nombre.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <p-inputMask formControlName="phone" mask="(999) 999-9999" styleClass="w-100 h-50px"></p-inputMask>
              <small class="ps-2">Número de télefono.</small>
            </div>
          </div>
          <div class="d-flex gap-2">
            <div class="d-flex flex-column gap-2 w-100">
              <input formControlName="email" type="email" pInputText class="w-100 h-50px" />
              <small class="ps-2">Correo electrónico.</small>
            </div>
          </div>
        </div>
      </div>

      <div class="card page-section rounded-2 shadow-sm">
        <div class="card-header align-items-center d-flex rounded-top-2">
          <h6 class="card-title flex-grow-1 m-0">Información de ubicación.</h6>
        </div>
        <div class="card-body">
          <div class="mb-3 d-flex gap-2">
            <div class="d-flex flex-column gap-2 w-50">
              <p-dropdown formControlName="state" [options]="states" optionLabel="name" optionValue="value"
                showClear="true" [filter]="true" filterBy="name" placeholder="Seleccione" styleClass="w-100 h-50px"
                (onChange)="changeState()">
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ item.name }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ item.name }}</div>
                </ng-template>
              </p-dropdown>
              <small class="ps-2">Estado.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <p-dropdown formControlName="municipality" [options]="municipalities" optionLabel="name"
                optionValue="value" showClear="true" [filter]="true" filterBy="name" placeholder="Seleccione"
                styleClass="w-100 h-50px" (onChange)="changeMunicipality()">
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ item.name }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ item.name }}</div>
                </ng-template>
              </p-dropdown>
              <small class="ps-2">Municipio.</small>
            </div>
          </div>
          <div class="mb-3 d-flex gap-2">
            <div class="d-flex flex-column gap-2 w-100">
              <p-autoComplete formControlName="address" [suggestions]="addressSuggestions" showClear="true"
                (completeMethod)="addressAutocomplete($event)" field="description" styleClass="w-100 h-50px"
                inputStyleClass="w-100 h-50px" (onSelect)="onSelectAddress()">
                <ng-template let-item pTemplate="item">
                  <div class="py-3 border-bottom">
                    <h5>{{ item.structured_formatting.main_text }}</h5>
                    <p class="m-0">{{ item.structured_formatting.secondary_text }}</p>
                  </div>
                </ng-template>
              </p-autoComplete>
              <small class="ps-2">Dirección.</small>
            </div>
          </div>
          <div class="d-flex gap-2">
            <div class="d-flex flex-column gap-2 w-50">
              <input formControlName="zipcode" type="text" pInputText class="w-100 h-50px" (change)="changeZipcode()" />
              <small class="ps-2">Código Postal.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <p-dropdown formControlName="colony" [options]="colonies" optionLabel="name" optionValue="value"
                showClear="true" [filter]="true" filterBy="name" placeholder="Seleccione" styleClass="w-100 h-50px">
                <ng-template let-item pTemplate="selectedItem">
                  <div>{{ item.name }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ item.name }}</div>
                </ng-template>
              </p-dropdown>
              <small class="ps-2">Colonia.</small>
            </div>
          </div>
        </div>
      </div>

      <div class="card page-section rounded-2 shadow-sm">
        <div class="card-header align-items-center d-flex rounded-top-2">
          <h6 class="card-title flex-grow-1 m-0">Información del inmueble.</h6>
        </div>
        <div class="card-body">
          <div class="mb-3 d-flex gap-2">
            <div class="d-flex flex-column gap-2 w-50">
              <p-dropdown formControlName="typeProperty" [options]="typeProperties" optionLabel="label"
                optionValue="value" [filter]="true" filterBy="label" placeholder="Seleccione" styleClass="w-100 h-50px">
                <ng-template pTemplate="selectedItem" let-selectedOption>
                  <div>{{ selectedOption.label }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ item.label }}</div>
                </ng-template>
              </p-dropdown>
              <small class="ps-2">Tipo de inmueble.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <p-inputNumber formControlName="area" styleClass="w-100" class="w-100 h-50px"
                inputStyleClass="text-center" inputId="minmaxfraction" mode="decimal" [minFractionDigits]="2"
                [maxFractionDigits]="2" />
              <small class="ps-2 text-center">Área en metros cuadrados.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <p-inputNumber formControlName="room" styleClass="w-100" class="w-100 h-50px"
                inputStyleClass="text-center" [min]="1" [max]="20" />
              <small class="ps-2 text-center">Número de recamaras.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <p-inputNumber formControlName="bath" styleClass="w-100" class="w-100 h-50px"
                inputStyleClass="text-center" [min]="1" [max]="20" mode="decimal" [minFractionDigits]="1"
                [maxFractionDigits]="1" />
              <small class="ps-2 text-center">Número de baños.</small>
            </div>
          </div>

          <div class="d-flex gap-2">
            <div class="d-flex flex-column gap-2 w-50">
              <p-inputNumber formControlName="debt" inputId="currency" inputStyleClass="w-100 h-50px"
                styleClass="w-100 h-50px" mode="currency" locale="es-MX" currency="MXN"></p-inputNumber>
              <small class="ps-2">Adeudos del inmueble.</small>
            </div>

            @if (this.debt.value > 0) {
            <div class="d-flex flex-column gap-2 w-50">
              <input formControlName="infonavit" type="text" pInputText class="w-100 h-50px" />
              <small class="ps-2">Número de crédito.</small>
            </div>
            <div class="d-flex flex-column gap-2 w-50">
              <input formControlName="cadastral" type="text" pInputText class="w-100 h-50px" />
              <small class="ps-2">Número de clave catastral.</small>
            </div>
            }

            <div class="d-flex flex-column gap-2 w-50">
              <p-inputNumber formControlName="desiredAmount" inputId="currency" inputStyleClass="w-100 h-50px"
                styleClass="w-100 h-50px" mode="currency" locale="es-MX" currency="MXN"></p-inputNumber>
              <small class="ps-2">Mónto deseado.</small>
            </div>
          </div>
        </div>
      </div>
    </form>
  </section>
</backbone>