import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { SolviiformaService } from '@services/solviiforma.service';
import { InputTextModule } from 'primeng/inputtext';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { RealEstateService } from '@services/realestate.service';
import { DropdownModule } from 'primeng/dropdown';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogService } from '@utils/confirm-dialog.service';
import { ToastService } from '@utils/toast.service';

@Component({
  selector: 'app-solviiforma-detail',
  standalone: true,
  imports: [
    BackboneComponent,
    BreadcrumbComponent,
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    InputMaskModule,
    InputNumberModule,
    DropdownModule,
    ButtonModule,
  ],
  templateUrl: './detail.component.html',
  styleUrl: './detail.component.scss'
})
export class SolviiformaDetailPage {
  private activatedRoute = inject(ActivatedRoute);
  private formBuilder = inject(FormBuilder);
  private solviiformaService = inject(SolviiformaService);
  private realEstateService = inject(RealEstateService);
  private confirmDialogService = inject(ConfirmDialogService);
  private toastService = inject(ToastService);
  private router = inject(Router);

  public isLoading = false;
  public isSaving = false;
  public isDeleting = false;

  public typeProperties: any[] = []
  public lead: any = undefined

  public form = this.formBuilder.group({
    name: [null, [Validators.required]],
    email: [null, [Validators.required]],
    phone: [null, [Validators.required]],

    state: [null, [Validators.required]],
    municipality: [null, [Validators.required]],
    zipcode: [null, [Validators.required]],
    colony: [null, [Validators.required]],
    address: [null, [Validators.required]],

    typeProperty: [null, [Validators.required]],
    area: [null, [Validators.required]],
    room: [null, [Validators.required]],
    bath: [null, [Validators.required]],
    debt: [null, [Validators.required]],
    infonavit: [null, [Validators.required]],
    cadastral: [null, [Validators.required]],
    desiredAmount: [null, [Validators.required]],

    tipoPrograma: [null, [Validators.required]],
    costoRehabilitacion: [null, [Validators.required]],
    pagoAdeudos: [null, [Validators.required]],
    pagoComisionBroker: [null, [Validators.required]],
    adeudoInfonavit: [null, [Validators.required]],
    gastosNotariales: [null, [Validators.required]],
    flujoRemanente: [null, [Validators.required]],
    feePlataforma: [null, [Validators.required]],
    estatusCredito: [null, [Validators.required]],
    adeudoVsValor: [null, [Validators.required]],
    porcentajeCliente: [null, [Validators.required]],
    pagoCliente: [null, [Validators.required]],
    montoLiquidoCliente: [null, [Validators.required]],
    utilidadBrutaPlataforma: [null, [Validators.required]],
    inversion: [null, [Validators.required]],
    retornoSobreInversion: [null, [Validators.required]],
    margen: [null, [Validators.required]],
    validacionOperacionCompra: [null, [Validators.required]],
    porcentajePagoVsPrecioVenta: [null, [Validators.required]],

    priceScore: [null, [Validators.required]],
    priceFinal: [null, [Validators.required]],
    priceByColony: [null, [Validators.required]],
    priceByZipcode: [null, [Validators.required]],
  });

  get name() { return this.form.get('name') }
  set name(value: any) { this.name.setValue(value) }
  get email() { return this.form.get('email') }
  set email(value: any) { this.email.setValue(value) }
  get phone() { return this.form.get('phone') }
  set phone(value: any) { this.phone.setValue(value) }

  get state() { return this.form.get('state') }
  set state(value: any) { this.state.setValue(value) }
  get municipality() { return this.form.get('municipality') }
  set municipality(value: any) { this.municipality.setValue(value) }
  get zipcode() { return this.form.get('zipcode') }
  set zipcode(value: any) { this.zipcode.setValue(value) }
  get colony() { return this.form.get('colony') }
  set colony(value: any) { this.colony.setValue(value) }
  get address() { return this.form.get('address') }
  set address(value: any) { this.address.setValue(value) }

  set typeProperty(value: any) { this.typeProperty.setValue(value) }
  get typeProperty() { return this.form.get('typeProperty') }
  set area(value: any) { this.area.setValue(value) }
  get area() { return this.form.get('area') }
  set room(value: any) { this.room.setValue(value) }
  get room() { return this.form.get('room') }
  set bath(value: any) { this.bath.setValue(value) }
  get bath() { return this.form.get('bath') }
  set debt(value: any) { this.debt.setValue(value) }
  get debt() { return this.form.get('debt') }
  set infonavit(value: any) { this.infonavit.setValue(value) }
  get infonavit() { return this.form.get('infonavit') }
  set cadastral(value: any) { this.cadastral.setValue(value) }
  get cadastral() { return this.form.get('cadastral') }
  set desiredAmount(value: any) { this.desiredAmount.setValue(value) }
  get desiredAmount() { return this.form.get('desiredAmount') }

  set tipoPrograma(value: any) { this.tipoPrograma.setValue(value) }
  get tipoPrograma() { return this.form.get('tipoPrograma') }
  set costoRehabilitacion(value: any) { this.costoRehabilitacion.setValue(value) }
  get costoRehabilitacion() { return this.form.get('costoRehabilitacion') }
  set pagoAdeudos(value: any) { this.pagoAdeudos.setValue(value) }
  get pagoAdeudos() { return this.form.get('pagoAdeudos') }
  set pagoComisionBroker(value: any) { this.pagoComisionBroker.setValue(value) }
  get pagoComisionBroker() { return this.form.get('pagoComisionBroker') }
  set adeudoInfonavit(value: any) { this.adeudoInfonavit.setValue(value) }
  get adeudoInfonavit() { return this.form.get('adeudoInfonavit') }
  set gastosNotariales(value: any) { this.gastosNotariales.setValue(value) }
  get gastosNotariales() { return this.form.get('gastosNotariales') }
  set flujoRemanente(value: any) { this.flujoRemanente.setValue(value) }
  get flujoRemanente() { return this.form.get('flujoRemanente') }
  set feePlataforma(value: any) { this.feePlataforma.setValue(value) }
  get feePlataforma() { return this.form.get('feePlataforma') }
  set estatusCredito(value: any) { this.estatusCredito.setValue(value) }
  get estatusCredito() { return this.form.get('estatusCredito') }
  set adeudoVsValor(value: any) { this.adeudoVsValor.setValue(value) }
  get adeudoVsValor() { return this.form.get('adeudoVsValor') }
  set porcentajeCliente(value: any) { this.porcentajeCliente.setValue(value) }
  get porcentajeCliente() { return this.form.get('porcentajeCliente') }
  set pagoCliente(value: any) { this.pagoCliente.setValue(value) }
  get pagoCliente() { return this.form.get('pagoCliente') }
  set montoLiquidoCliente(value: any) { this.montoLiquidoCliente.setValue(value) }
  get montoLiquidoCliente() { return this.form.get('montoLiquidoCliente') }
  set utilidadBrutaPlataforma(value: any) { this.utilidadBrutaPlataforma.setValue(value) }
  get utilidadBrutaPlataforma() { return this.form.get('utilidadBrutaPlataforma') }
  set inversion(value: any) { this.inversion.setValue(value) }
  get inversion() { return this.form.get('inversion') }
  set retornoSobreInversion(value: any) { this.retornoSobreInversion.setValue(value) }
  get retornoSobreInversion() { return this.form.get('retornoSobreInversion') }
  set margen(value: any) { this.margen.setValue(value) }
  get margen() { return this.form.get('margen') }
  set validacionOperacionCompra(value: any) { this.validacionOperacionCompra.setValue(value) }
  get validacionOperacionCompra() { return this.form.get('validacionOperacionCompra') }
  set porcentajePagoVsPrecioVenta(value: any) { this.porcentajePagoVsPrecioVenta.setValue(value) }
  get porcentajePagoVsPrecioVenta() { return this.form.get('porcentajePagoVsPrecioVenta') }

  set priceScore(value: any) { this.priceScore.setValue(value) }
  get priceScore() { return this.form.get('priceScore') }
  set priceFinal(value: any) { this.priceFinal.setValue(value) }
  get priceFinal() { return this.form.get('priceFinal') }
  set priceByColony(value: any) { this.priceByColony.setValue(value) }
  get priceByColony() { return this.form.get('priceByColony') }
  set priceByZipcode(value: any) { this.priceByZipcode.setValue(value) }
  get priceByZipcode() { return this.form.get('priceByZipcode') }

  constructor() {
    // this.name?.disable()
    // this.email?.disable()
    // this.phone?.disable()

    this.state?.disable()
    this.municipality?.disable()
    this.zipcode?.disable()
    this.colony?.disable()
    this.address?.disable()

    // this.typeProperty?.disable()
    // this.area?.disable()
    // this.room?.disable()
    // this.bath?.disable()
    // this.debt?.disable()
    // this.infonavit?.disable()
    // this.cadastral?.disable()
    // this.desiredAmount?.disable()

    this.tipoPrograma?.disable()
    // this.costoRehabilitacion?.disable()
    this.pagoAdeudos?.disable()
    this.pagoComisionBroker?.disable()
    this.adeudoInfonavit?.disable()
    // this.gastosNotariales?.disable()
    this.flujoRemanente?.disable()
    this.feePlataforma?.disable()
    this.estatusCredito?.disable()
    this.adeudoVsValor?.disable()
    this.porcentajeCliente?.disable()
    this.pagoCliente?.disable()
    this.montoLiquidoCliente?.disable()
    this.utilidadBrutaPlataforma?.disable()
    this.inversion?.disable()
    this.retornoSobreInversion?.disable()
    this.margen?.disable()
    this.validacionOperacionCompra?.disable()
    this.porcentajePagoVsPrecioVenta?.disable()

    this.priceScore?.disable()
    // this.priceFinal?.disable()
    this.priceByColony?.disable()
    this.priceByZipcode?.disable()
  }

  async ngOnInit() {
    await this.realEstateService.getTypes().then(response => response.content.map((item: any) => this.typeProperties.push({ label: item.label, value: item.propertyTypeId })))

    this.activatedRoute.params.subscribe(async routeParams => {
      await this.solviiformaService.getDetails(routeParams['id']).then((response: any) => {
        this.lead = response;

        this.name = this.lead.name;
        this.email = this.lead.email;
        this.phone = this.lead.phone;

        this.state = this.lead.state;
        this.municipality = this.lead.municipality;
        this.zipcode = this.lead.zipcode;
        this.colony = this.lead.colony;
        this.address = this.lead.address;

        this.typeProperty = Number(this.lead.typeProperty);
        this.area = this.lead.area;
        this.room = this.lead.room;
        this.bath = this.lead.bath;
        this.debt = Number(this.lead.debt);
        this.infonavit = this.lead.infonavit;
        this.cadastral = this.lead.cadastral;
        this.desiredAmount = Number(this.lead.desiredAmount);

        this.tipoPrograma = this.lead.validationPurchaseTransaction.tipoPrograma;
        this.costoRehabilitacion = this.lead.validationPurchaseTransaction.costoRehabilitacion;
        this.pagoAdeudos = this.lead.validationPurchaseTransaction.pagoAdeudos;
        this.pagoComisionBroker = this.lead.validationPurchaseTransaction.pagoComisionBroker;
        this.adeudoInfonavit = this.lead.validationPurchaseTransaction.adeudoInfonavit;
        this.gastosNotariales = this.lead.validationPurchaseTransaction.gastosNotariales;
        this.flujoRemanente = this.lead.validationPurchaseTransaction.flujoRemanente;
        this.feePlataforma = this.lead.validationPurchaseTransaction.feePlataforma;
        this.estatusCredito = this.lead.validationPurchaseTransaction.estatusCredito;
        this.adeudoVsValor = this.lead.validationPurchaseTransaction.adeudoVsValor;
        this.porcentajeCliente = this.lead.validationPurchaseTransaction.porcentajeCliente;
        this.pagoCliente = this.lead.validationPurchaseTransaction.pagoCliente;
        this.montoLiquidoCliente = this.lead.validationPurchaseTransaction.montoLiquidoCliente;
        this.utilidadBrutaPlataforma = this.lead.validationPurchaseTransaction.utilidadBrutaPlataforma;
        this.inversion = this.lead.validationPurchaseTransaction.inversion;
        this.retornoSobreInversion = this.lead.validationPurchaseTransaction.retornoSobreInversion;
        this.margen = this.lead.validationPurchaseTransaction.margen;
        this.validacionOperacionCompra = this.lead.validationPurchaseTransaction.validacionOperacionCompra;
        this.porcentajePagoVsPrecioVenta = this.lead.validationPurchaseTransaction.porcentajePagoVsPrecioVenta;

        this.priceScore = Number(this.lead.calculatorApi.score);
        this.priceFinal = Number(this.lead.calculatorApi.final);
        this.priceByColony = Number(this.lead.calculatorApi.colony);
        this.priceByZipcode = Number(this.lead.calculatorApi.zipcode);
      }).catch((error: any) => {
        this.toastService.error('No se encontro el registro solicitado.')
        this.router.navigate(['/solviiforma'])
      })
    });
  }

  save = async () => {
    this.isSaving = true

    await this.solviiformaService.updateLead(this.lead.leadsSolviiformaId, this.form.getRawValue()).then(response => {
      this.toastService.success('Se actualizo el lead.')

      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true })
        .then(() => this.router.navigate([`/leads/solviiforma/${this.lead.leadsSolviiformaId}`]));
    })

    this.isSaving = false
  }

  delete = (event: Event) => {
    this.isDeleting = true;

    this.confirmDialogService.dialog('¿Está seguro de eliminar esta propiedad?', 'Continuar', 'Cancelar')
      .then(() => {
        this.isLoading = true;

        this.solviiformaService.delete(this.lead.leadsSolviiformaId)
          .then(() => {
            this.toastService.success('El registro ha sido eliminado correctamente.')
            this.router.navigate(['/solviiforma'])
          })
          .catch((error: any) => {
            this.toastService.error(error.error.data.message)
            this.isDeleting = false;
            this.isLoading = false;
          })
      })
      .catch(() => this.isDeleting = false)
  }
}
