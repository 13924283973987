import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BackboneComponent } from '@components/backbone/backbone.component';
import { BreadcrumbComponent } from '@components/breadcrumb/breadcrumb.component';
import { CatalogService } from '@services/catalog.service';
import { RealEstateService } from '@services/realestate.service';
import { SolviiformaService } from '@services/solviiforma.service';
import { ToastService } from '@utils/toast.service';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-solviiforma-create',
  standalone: true,
  imports: [
    BackboneComponent,
    BreadcrumbComponent,
    CommonModule,
    ReactiveFormsModule,
    InputTextModule,
    InputMaskModule,
    InputNumberModule,
    DropdownModule,
    ButtonModule,
    AutoCompleteModule,
  ],
  templateUrl: './create.component.html',
  styleUrl: './create.component.scss'
})
export class SolviiformaCreatePage {
  private formBuilder = inject(FormBuilder);
  private catalogService = inject(CatalogService);
  private realEstateService = inject(RealEstateService);
  private solviiformaService = inject(SolviiformaService);
  private toastService = inject(ToastService);
  private router = inject(Router);

  public isLoading = false;
  public isSaving = false;
  public isDeleting = false;

  public states: object[] = []
  public municipalities: object[] = []
  public addressSuggestions: any[] = []
  public colonies: any[] = []
  public typeProperties: any[] = []

  public form = this.formBuilder.group({
    name: [null, [Validators.required]],
    email: [null, [Validators.required]],
    phone: [null, [Validators.required]],

    state: [null, [Validators.required]],
    municipality: [null, [Validators.required]],
    zipcode: [null, [Validators.required]],
    colony: [null, [Validators.required]],
    address: [null, [Validators.required]],
    placeId: [null, [Validators.required]],

    typeProperty: [null, [Validators.required]],
    area: [null, [Validators.required]],
    room: [null, [Validators.required]],
    bath: [null, [Validators.required]],
    debt: [null],
    infonavit: [null],
    cadastral: [null],
    desiredAmount: [null],
  });

  get name() { return this.form.get('name') }
  set name(value: any) { this.name.setValue(value) }
  get email() { return this.form.get('email') }
  set email(value: any) { this.email.setValue(value) }
  get phone() { return this.form.get('phone') }
  set phone(value: any) { this.phone.setValue(value) }

  get state() { return this.form.get('state') }
  set state(value: any) { this.state.setValue(value) }
  get municipality() { return this.form.get('municipality') }
  set municipality(value: any) { this.municipality.setValue(value) }
  get zipcode() { return this.form.get('zipcode') }
  set zipcode(value: any) { this.zipcode.setValue(value) }
  get colony() { return this.form.get('colony') }
  set colony(value: any) { this.colony.setValue(value) }
  get address() { return this.form.get('address') }
  set address(value: any) { this.address.setValue(value) }
  get placeId() { return this.form.get('placeId') }
  set placeId(value: any) { this.placeId.setValue(value) }

  set typeProperty(value: any) { this.typeProperty.setValue(value) }
  get typeProperty() { return this.form.get('typeProperty') }
  set area(value: any) { this.area.setValue(value) }
  get area() { return this.form.get('area') }
  set room(value: any) { this.room.setValue(value) }
  get room() { return this.form.get('room') }
  set bath(value: any) { this.bath.setValue(value) }
  get bath() { return this.form.get('bath') }
  set debt(value: any) { this.debt.setValue(value) }
  get debt() { return this.form.get('debt') }
  set infonavit(value: any) { this.infonavit.setValue(value) }
  get infonavit() { return this.form.get('infonavit') }
  set cadastral(value: any) { this.cadastral.setValue(value) }
  get cadastral() { return this.form.get('cadastral') }
  set desiredAmount(value: any) { this.desiredAmount.setValue(value) }
  get desiredAmount() { return this.form.get('desiredAmount') }

  ngOnInit() {
    this.catalogService.getStates().then(response => response.map((item: any) => this.states.push({ name: item, value: item })))
    this.realEstateService.getTypes().then(response => response.content.map((item: any) => this.typeProperties.push({ label: item.label, value: item.propertyTypeId })))

    this.municipality.disable()
    this.address.disable()
    this.zipcode.disable()
    this.colony.disable()

    this.address.valueChanges.subscribe((changes: any) => {
      if (changes !== null) {
        this.zipcode.enable()
        this.colony.enable()
      } else {
        this.zipcode = null
        this.colony = null
        this.zipcode.disable()
        this.colony.disable()
      }
    })
  }

  async changeState() {
    this.municipality.disable()
    this.address.disable()

    if (this.state.value !== null) {
      this.municipalities = []
      this.municipality = null
      this.address = null

      await this.catalogService.getMunicipalities(this.state.value).then(response => {
        this.municipalities = []

        response.map((item: any) => this.municipalities.push({ name: item, value: item }))
      })

      this.municipality.enable()
    }
  }

  async changeMunicipality() {
    this.address.disable()
    this.address = null

    if (this.municipality.value !== null) {
      this.address.enable()
      this.changeZipcode()
    }
  }

  async onSelectAddress() {
    this.address = this.address.value.description

    await this.catalogService.getAddress(this.address.value).then(response => {
      if (response.length >= 1) {
        this.placeId = response[0].place_id

        this.zipcode = response[0].zip
        this.changeZipcode()
      }
    })
  }

  async changeZipcode() {
    let filtered: any[] = []
    this.colony = null

    if (this.zipcode.value !== null && this.zipcode.value !== '') {
      // Buscar colonias existentes por cp en nuestra plataforma
      await this.catalogService.getColoniesFeat3Prices(this.zipcode.value).then(response => {
        response = response.sort()
        response.map((item: any) => filtered.push(item.colonia))
      })

      // Buscar colonias por código postal
      await this.catalogService.getColonies(this.zipcode.value, 'zipcode').then(response => {
        response = response.sort()
        response.map((item: any) => filtered.push(item))
      })
    } else {
      if (this.municipality.value !== null) {
        // Buscar colonias por municipio
        await this.catalogService.getColonies(this.municipality.value).then(response => {
          response = response.sort()
          response.map((item: any) => filtered.push(item))
        })
      }
    }

    // Limpiar las colonias repetidas
    let result = filtered.filter((item, index) => filtered.indexOf(item) === index)
    filtered = []
    result.map((item: string) => filtered.push({ name: item, value: item }))

    this.colonies = filtered
  }

  async addressAutocomplete(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = []
    let state: any = {}
    let query = event.query

    await this.catalogService.getAddress(`${this.municipality.value}, ${this.state.value}, México`).then(response => state = response[0])

    let address = `${query}, ${this.municipality.value}, ${this.state.value}, México`
    let lat = state.geometry.location.lat
    let lng = state.geometry.location.lng

    await this.catalogService.getAddressAutocomplete(address, lat, lng).then(response => filtered = response)

    this.addressSuggestions = filtered
  }

  async save() {
    this.isSaving = true

    await this.solviiformaService.newLead(this.form.getRawValue()).then(response => {
      this.toastService.success('Se agregó el lead.')
      this.router.navigate([`/solviiforma/${response.leadId}`])
    })
  }
}
