import { inject, Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';

@Injectable({
  providedIn: 'root'
})
export class SolviiformaService {
  private http = inject(HttpService)

  public getList = async () => await this.http.get(`/v1/solviiforma`, false)
    .then((response: any) => response.data)
    .catch((error: any) => error)

  public getDetails = async (id: number) => await this.http.get(`/v1/solviiforma/${id}`, false)
    .then((response: any) => response.data.content)

  public delete = async (id: string) => await this.http.delete(`/v1/solviiforma/${id}`)
    .then((response: any) => response.data)

  public newLead = async (post: any) => await this.http.post(`/v1/solviiforma`, post, false, false, false)
    .then((response: any) => response.data)
    .catch((error: any) => error)

  public updateLead = async (id: number, post: any) => await this.http.put(`/v1/solviiforma/${id}`, post, false)
    .then((response: any) => response.data)
    .catch((error: any) => error)
}
