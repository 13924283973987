<backbone>
  <section header-page="topbar" class="d-flex h-100 position-relative">
    <div class="w-50 d-flex justify-content-start align-items-center">
      <p class="m-0 fw-medium">Lista de leads</p>
    </div>

    <div class="w-50 d-flex justify-content-end align-items-center gap-3">
      <breadcrumb [links]="[{title: 'Dashboard'}, {title: 'Lista de leads'}]" />

      <a class="btn btn-primary" routerLink="create"><small>Nuevo</small></a>
    </div>
  </section>

  <section wrapper>
    <div class="page-section card">
      <div class="card-body p-0">
        <p-table #dt1 [value]="leadsList" dataKey="id" [rows]="25" [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[25, 50, 100]" [paginator]="true"
          currentPageReportTemplate="Mostrando {first} de {last} de un total de {totalRecords} cartas de confirmación."
          [globalFilterFields]="['name', 'email', 'state', 'municipality', 'zipcode', 'colony', 'address', 'status']">
          <ng-template pTemplate="caption">
            <div class="d-flex align-items-stretch justify-content-between gap-2">
              <span class="p-input-icon-left ml-auto w-50">
                <i class="pi pi-search"></i>
                <input pInputText type="text" [(ngModel)]="searchValue" class="w-100 h-50px"
                  (input)="dt1.filterGlobal(searchValue, 'contains')"
                  placeholder="Buscar por nombre, correo electrónico, estado, municipio, código postal, colonia, dirección, estatus." />
              </span>
              <p-button label="Limpiar busqueda" [outlined]="true" icon="pi pi-filter-slash" (click)="clear(dt1)"
                styleClass="h-50px" />
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th>Nombre</th>
              <th>Correo Electrónico</th>
              <th>Estado</th>
              <th>Municipio</th>
              <th class="text-center">CP</th>
              <th>Colonia</th>
              <th>Dirección</th>
              <th>Precio</th>
              <th>Estatus</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-lead>
            <tr>
              <td class="text-nowrap"><a [routerLink]="[lead.leadsSolviiformaId]"><small>{{lead.name}}</small></a></td>
              <td class="text-nowrap"><small>{{lead.email}}</small></td>
              <td class="text-nowrap"><small>{{lead.state}}</small></td>
              <td class="text-nowrap"><small>{{lead.municipality}}</small></td>
              <td class="text-nowrap text-center"><small>{{lead.zipcode}}</small></td>
              <td class="" style="max-width: 200px;"><small>{{lead.colony}}</small></td>
              <td class="" style="max-width: 200px;"><small>{{lead.address}}</small></td>
              <td class="text-nowrap">
                <!-- <small>MX{{lead.price | currency: 'MXN'}}</small> -->
              </td>
              <td class="text-nowrap">
                <p-tag
                  [value]="(lead.validationPurchaseTransaction?.validacionOperacionCompra === 'SI') ? 'Válido' : 'No viable'"
                  [severity]="getSeverity(lead.validationPurchaseTransaction?.validacionOperacionCompra)"
                  styleClass="fw-normal small" />
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </section>
</backbone>